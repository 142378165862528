
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'

@Component
export default class QRStatus extends Vue {
  @State(({ userState }) => userState.ticket) ticket!: any

  @Action changeMyCardStatus!: Function
  @Action getTicketInfo!: Function

  @Getter ticketHasQuotas!: Function
  @Getter isLinkValid!: Function
  public ticketId = ''
  public showStatus = false

  async mounted() {
    const { id } = this.$route.params
    /* Esto es para redireccionar a nuevo proyecto TQR-ECOMMERCE */
    window.location.href = `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/purchase?uuid=${id}`
    await this.getTicketInfo(id)
/*
    if (this.isLinkValid && this.ticketHasQuotas) {
      this.$router.push('/ticket')
    }

    if (this.isLinkValid && !this.ticketHasQuotas) {
      this.$router.push('/ticket')
    } else if (!this.isLinkValid || !this.ticketHasQuotas) {
      this.showStatus = true
    }
*/
  }
}
